import { FC } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

interface CircleItem {
	icon: JSX.Element
	text: string
	link: string
	subText?: string
}

interface CircleI {
	item: CircleItem
	breakpoints: {
		xs?: number
		sm?: number
		md?: number
		lg?: number
	}
}

const Circle: FC<CircleI> = ({ item, breakpoints }) => {

	return (
		<Grid item key={item.text} {...breakpoints} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			<Link href={item.link} target='_blank' underline='none' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				<Box
					width='120px'
					height='120px'
					display='flex'
					justifyContent='center'
					alignItems='center'
					sx={{
						cursor: 'pointer',
						borderRadius: '50%',
						backgroundColor: theme => theme.palette.background.default,
						transition: 'all 0.3s ease-in-out',
						'&:hover': { transform: 'scale(1.05)' }
					}}
				>
					{item.icon}
				</Box>
				<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginTop={2} sx={{ cursor: 'pointer' }}>
					<Typography variant='h5' color={theme => theme.palette.primary.contrastText}>
						{item.text}
					</Typography>
					<Typography variant='body2' color={theme => theme.palette.primary.contrastText}>
						{item.subText}
					</Typography>
				</Box>
			</Link>
		</Grid>
	)
}

export default Circle
