import { FC, ReactElement } from 'react'

import Tooltip from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'

interface TooltipI {
	isVisible: boolean
	children: ReactElement
	title?: string
	placement?: 'top' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | undefined
}

const  CustomTooltip: FC<TooltipI> = ({isVisible, title, placement, children}) => {
	return (
		<Tooltip
			title={isVisible && !title ? 'Click for more info' : title}
			placement={placement ? placement : 'top'}
			TransitionComponent={Zoom}
			disableInteractive
			arrow
			PopperProps={{
				sx: {
					'& .MuiTooltip-tooltip': {
						backgroundColor: theme => theme.palette.primary.main,
						color: theme => theme.palette.primary.contrastText
					},
					'& .MuiTooltip-arrow': {
						color: theme => theme.palette.primary.main
					}
				}
			}}
		>
			{children}
		</Tooltip>
	)
}

export default CustomTooltip
