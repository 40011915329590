import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { useRefContext } from 'context/RefContext'


const HomeView: FC = () => {

	const { t } = useTranslation()

	const { HomeRef, ProjectsRef, ContactRef } = useRefContext()

	return (
		<Box ref={HomeRef} display='flex' flexDirection='column' justifyContent='space-between' alignItems='center' height='80vh'>
			<Box display='flex' height='100%' flexDirection='column' justifyContent='space-evenly' paddingTop={5} alignItems='center' sx={{userSelect: 'none'}}>
				<Box/>
				<Box display='flex' flexDirection='column' alignItems='start'>
					<Typography variant='h6' textTransform='uppercase' sx={{ color: theme => theme.palette.text.primary }}>
						{t('Hello there, Welcome to my site')}
					</Typography>
					<Typography variant='h1' color={ theme => theme.palette.text.primary }>
						{t('I\'m Tommy Riquet')}
					</Typography>
					<Typography variant='h3' color={ theme => theme.palette.primary.dark }>
						{t('A Full Stack Developer')}
					</Typography>
					<Typography variant='h3' color={theme => theme.palette.text.primary }>
						{t('& UI/UX Designer')}
					</Typography>
					<Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' width='100%' mt={4}>
						<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{cursor: 'pointer'}}>
							<Button
								variant='contained'
								color='primary'
								onClick={() => (ProjectsRef?.current as any).scrollIntoView({behavior: 'smooth'})}
								sx={{
									borderRadius: '4px',
									marginRight: 2,
									'&:hover': {
										backgroundColor: theme => theme.palette.primary.dark
									}
								}}
							>
								<Typography variant='h6' sx={{ color: theme => theme.palette.primary.contrastText }}>
									{t('Projects')}
								</Typography>
							</Button>
							<Button
								variant='outlined'
								color='primary'
								onClick={() => (ContactRef?.current as any).scrollIntoView({behavior: 'smooth'})}
								sx={{
									borderRadius: '4px',
									'&:hover': {
										backgroundColor: theme => theme.palette.primary.dark,
										border: theme => `1px solid ${theme.palette.primary.dark}`,
										'& .MuiTypography-root': {
											color: theme => theme.palette.primary.contrastText
										}
									}
								}}
							>
								<Typography variant='h6' sx={{ color: theme => theme.palette.text.primary }}>
									{t('Contact me')}
								</Typography>
							</Button>
						</Box>
					</Box>
				</Box>
				<Box>
					<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={4} sx={{cursor: 'pointer'}}>
						<KeyboardArrowDownIcon
							onClick={() => (ProjectsRef?.current as any).scrollIntoView({behavior: 'smooth'})}
							sx={{
								fontSize: '4rem',
								color: theme => theme.palette.primary.main,
								transition: 'all 0.3s ease-in-out',
								animation: 'up-down-movement 1s ease-in-out alternate infinite',
								'&:hover': {
									transform: 'scale(1.1)',
									color: theme => theme.palette.primary.dark
								},
								'@keyframes up-down-movement': {
									from: {
										transform: 'translateY(0px)'
									},
									to: {
										transform: 'translateY(5px)'
									}
								}
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default HomeView
