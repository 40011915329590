import { FC } from 'react'

import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'
import Navbar from './Navbar'
import Footer from './Footer/Footer'


const MainScreen : FC = () => {

	return (
		<Box display='flex' flexDirection='column'>
			<Navbar/>
			<Outlet/>
			<Footer/>
		</Box>
	)
}
export default MainScreen
