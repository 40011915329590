import { FC, useState } from 'react'

import i18n from 'i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import french from 'static/icons/languages/french.png'
import english from 'static/icons/languages/english.png'


interface ChangeLanguageMenuI {
	color?: string
	hideArrow?: boolean
}

const ChangeLanguageMenu: FC<ChangeLanguageMenuI> = ({color, hideArrow}) => {

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
		localStorage.setItem('language', lng)
		handleClose()
	}

	return (
		<Box>
			<Button
				aria-controls='language-menu'
				aria-haspopup='true'
				aria-expanded={false}
				size='small'
				onClick={handleClick}
				sx={{
					color: theme => theme.palette.secondary.contrastText,
					fontWeight: 'bold',
					transition: 'all 0.3s ease-in-out',
					'&:hover': {
						transform: 'scale(1.05)'
					}
				}}
			>
				<LanguageIcon fontSize='small' sx={{color: color ? color : 'inherit'}}/>
				<KeyboardArrowUpIcon
					fontSize='small'
					sx={{
						display: hideArrow ? 'none' : 'inherit',
						color: color ? color : 'inherit',
						transition: 'all 0.3s ease-in-out',
						transform: open ? 'rotate(-180deg)' : 'rotate(-90deg)'
					}}
				/>
			</Button>
			<Menu
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				id='language-menu'
				keepMounted
				onClose={handleClose}
				open={open}
			>
				<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' padding={1}>
					<Button fullWidth onClick={() => changeLanguage('en')} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
						<img src={english} alt='english' width='20px' height='20px' style={{ marginRight: '1rem' }}/>
						<Typography variant='button'>
							English
						</Typography>
					</Button>
					<Button fullWidth onClick={() => changeLanguage('fr')} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
						<img src={french} alt='french' width='20px' height='20px' style={{ marginRight: '1rem' }}/>
						<Typography variant='button'>
							Français
						</Typography>
					</Button>
				</Box>
			</Menu>
		</Box>
	)
}

export default ChangeLanguageMenu
