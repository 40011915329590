import { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import theme from 'theme'

const Footer:FC = () => {


	return (
		<Box position='relative' bottom={0} display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center' sx={{backgroundColor: theme => theme.palette.secondary.main}}>
			<Typography variant='caption' sx={{ color: theme.palette.primary.contrastText }}>
				© Tommy Riquet {new Date().getFullYear()} - All rights reserved - BE 1002.558.247
			</Typography>
		</Box>
	)
}

export default Footer
