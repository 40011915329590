import createTheme from '@mui/material/styles/createTheme'

const theme = createTheme({
	typography: {
		fontFamily: '"Colfax", sans-serif',
		h1: {
			fontSize: 'clamp(2.5rem, 2.5rem + 1.4*(100vw - 36rem)/39, 4rem)',
			fontWeight: 700,
			lineHeight: 1.05,
			letterSpacing: '-.035em'
		},
		h2: {
			fontSize: 'clamp(2rem, 2rem + 0.8*(100vw - 36rem)/39, 3rem)',
			fontWeight: 700,
			lineHeight: 1.05,
			letterSpacing: '-.035em'
		},
		h3: {
			fontSize: 'clamp(1.5rem, 1.5rem + 0.6*(100vw - 36rem)/39, 2rem)',
			fontWeight: 700,
			lineHeight: 1.2,
			letterSpacing: '-.02em'
		},
		h4: {
			fontSize: 'clamp(1.2rem, 1.2rem + 0.48*(100vw - 36rem)/39, 1.8rem)',
			fontWeight: 700,
			lineHeight: 1.35,
			letterSpacing: '-.02em'
		},
		h5: {
			fontSize: 'clamp(1rem, 1rem + 0.4*(100vw - 36rem)/39, 1.5rem)',
			fontWeight: 700,
			lineHeight: 1.35,
			letterSpacing: '-.02em'
		},
		body1: {
			fontSize: 'clamp(1rem, 1rem + 0.4*(100vw - 36rem)/39, 1.5rem)',
			fontWeight: 400,
			lineHeight: 1.35,
			letterSpacing: '-.02em'
		},
		body2: {
			fontSize: 'clamp(0.8rem, 0.8rem + 0.32*(100vw - 36rem)/39, 1.2rem)',
			fontWeight: 400,
			lineHeight: 1.5,
			letterSpacing: '-.02em'
		}
	},
	palette: {
		primary: {
			light: '#303037', // dark gray
			main: '#1b1b20;', // black
			dark: '#00c057', // green
			contrastText: '#f6f6f7' //white
		},
		secondary: {
			light: '#00e065', // light green
			main: '#00c057', // green
			dark: '#00ad4e', // dark green
			contrastText: '#f6f6f7' // black
		},
		background: {
			default: '#f6f6f7' // dark white
		},
		text: {
			primary: '#1b1b20', // black
			secondary: '#f6f6f7' // white
		}
	}
})

export default theme
