import { FC } from 'react'

import Box from '@mui/material/Box'

import GitHubIcon from '@mui/icons-material/GitHub'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import MailIcon from '@mui/icons-material/Mail'

import theme from 'theme'

export const socials = [
	{
		name: 'GitHub',
		icon: <GitHubIcon sx={{ color: theme.palette.secondary.main }}/>,
		link: 'https://github.com/TommyRiquet'
	},
	{
		name: 'LinkedIn',
		icon: <LinkedInIcon sx={{ color: theme.palette.secondary.main }}/>,
		link: 'https://www.linkedin.com/in/tommy-riquet/'
	},
	{
		name: 'Mail',
		icon: <MailIcon sx={{ color: theme.palette.secondary.main }}/>,
		link: 'mailto:tommy@riquet.dev'
	}
]


const Socials: FC = () => {
	return (
		<Box display='inline-flex'>
			{
				socials.map( ( social ) => {
					return (
						<Box key={social.name} display='flex' flexDirection='row' justifyContent='center' alignItems='center' mx={1}>
							<a href={social.link} target='_blank' rel='noreferrer'>
								{social.icon}
							</a>
						</Box>
					)
				})
			}
		</Box>
	)
}

export default Socials
