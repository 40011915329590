import { FC } from 'react'

import Box from '@mui/material/Box'

import { GrayLogo } from 'components/common'

import insens from 'static/icons/collaborators/insens.webp'
import sanscollier from 'static/icons/collaborators/sanscollier.png'
import bureausvg from 'static/icons/collaborators/bureausvg.png'
import detendezvous from 'static/icons/collaborators/detendez-vous.png'
import jaune2 from 'static/icons/collaborators/jaune2.png'


const CollaborationView:FC = () => {

	return (
		<Box
			display='flex'
			flexDirection='row'
			alignItems='center'
			marginLeft='auto'
			marginRight='auto'
			border='1px solid lightgray'
			paddingY={2}
			maxWidth='1920px'
			flexWrap='nowrap'
			position='relative'
			sx={{
				backgroundColor: 'white',
				overflowX: 'hidden',
				mask: 'linear-gradient(to right, transparent, white 10%, white 90%, transparent)',
				webkitMask: 'linear-gradient(to right, transparent, white 10%, white 90%, transparent)'
			}}>
			<Box
				display='flex'
				flexDirection='row'
				alignItems='center'
				sx={{
					animation: 'slide 10s linear infinite',
					'@keyframes slide': {
						'0%': { transform: 'translateX(0)' },
						'100%': { transform: 'translateX(calc(-100% / 3))' }
					}
				}}>
				<GrayLogo src={insens} alt='Insens'/>
				<GrayLogo src={sanscollier} alt='Sans Collier'/>
				<GrayLogo src={bureausvg} alt='Bureau SVG'/>
				<GrayLogo src={jaune2} alt='Jaune2'/>
				<GrayLogo src={detendezvous} alt='Détendez-vous' link='https://detendez-vous.be/'/>
				<GrayLogo src={insens} alt='Insens'/>
				<GrayLogo src={sanscollier} alt='Sans Collier'/>
				<GrayLogo src={bureausvg} alt='Bureau SVG'/>
				<GrayLogo src={jaune2} alt='Jaune2'/>
				<GrayLogo src={detendezvous} alt='Détendez-vous' link='https://detendez-vous.be/'/>
				<GrayLogo src={insens} alt='Insens'/>
				<GrayLogo src={sanscollier} alt='Sans Collier'/>
				<GrayLogo src={bureausvg} alt='Bureau SVG'/>
				<GrayLogo src={jaune2} alt='Jaune2'/>
				<GrayLogo src={detendezvous} alt='Détendez-vous' link='https://detendez-vous.be/'/>
			</Box>
		</Box>
	)
}

export default CollaborationView

