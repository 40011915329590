import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'

import DevServiceView from './DevServiceView'
import DesignServiceView from './DesignServiceView'
import DevOpsServiceView from './DevOpsServiceView'

import { useRefContext } from 'context/RefContext'
import { useGlobalContext } from 'context/GlobalContext'


const ServiceViewSection: FC<{children: any}> = ({children}) => {

	const { isMobile } = useGlobalContext()

	const windowHeight = isMobile ? '0px' : '-200px'

	return (
		<motion.div
			viewport={{ once: true, margin: windowHeight }}
			initial={{
				y: 50,
				opacity: 0
			}}
			whileInView={{
				opacity: 1,
				y: 0,
				transition: {
					duration: 0.5,
					delay: 0.5
				}
			}}
			style={{margin: '4 0'}}
		>
			{children}
		</motion.div>
	)
}




const ServiceView:FC = () => {

	const { t } = useTranslation()

	const { ServiceRef } = useRefContext()

	return (
		<Box ref={ServiceRef} display='flex' flexDirection='column' justifyContent='center' alignItems='center' paddingY={16} paddingX={5} gap={8}>
			<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				<Typography flexWrap='wrap' variant='h2' sx={{ color: theme => theme.palette.primary.main, marginBottom: 2}}>
					{t('What I do')}
				</Typography>
				<Typography variant='body1' textAlign='center' sx={{ color: theme => theme.palette.text.primary, marginBottom: 4, flexWrap: 'balance' }}>
					{t('Blending creativity and technology to craft seamless digital solutions')}
				</Typography>
			</Box>
			<ServiceViewSection>
				<DesignServiceView/>
			</ServiceViewSection>
			<ServiceViewSection>
				<DevServiceView/>
			</ServiceViewSection>
			<ServiceViewSection>
				<DevOpsServiceView/>
			</ServiceViewSection>
		</Box>
	)
}

export default ServiceView
