import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import { Circle } from 'components/common'

import github from 'static/icons/skills/github.svg'
import linkedin from 'static/icons/assets/linkedin.svg'
import mail from 'static/icons/assets/mail.png'

import { useRefContext } from 'context/RefContext'

import theme from 'theme'

const contacts = [
	{
		text: 'Github',
		subText: 'TommyRiquet',
		icon: <img src={github} alt='github' width='70px' height='70px' style={{ objectFit: 'contain', userSelect: 'none', pointerEvents: 'none' }} />,
		link: 'https://github.com/TommyRiquet'
	},
	{
		text: 'LinkedIn',
		subText: 'Tommy Riquet',
		icon: <img src={linkedin} alt='linkedin' width='70px' height='70px' style={{ objectFit: 'contain', userSelect: 'none', pointerEvents: 'none' }} />,
		link: 'https://www.linkedin.com/in/tommy-riquet/'
	},
	{
		text: 'Mail',
		subText: 'tommy@riquet.dev',
		icon: <img src={mail} alt='linkedin' width='70px' height='70px' style={{ objectFit: 'contain', userSelect: 'none', pointerEvents: 'none' }} />,
		link: 'mailto:tommy@riquet.dev'
	}
]

const ContactView:FC = () => {

	const { t } = useTranslation()

	const { ContactRef } = useRefContext()

	return (
		<Box
			sx={{ backgroundColor: theme => theme.palette.secondary.main }}>
			<Box ref={ContactRef} display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' paddingY={16} paddingX={5}>
				<Typography variant='h2' sx={{ color: theme.palette.primary.contrastText, marginBottom: 4 }}>
					{t('Let\'s get in touch')}
				</Typography>
				<Typography variant='body1' sx={{ color: theme.palette.primary.contrastText, marginBottom: 8 }}>
					{t('You can contact me on any of these platforms')}
				</Typography>
				<Grid container mt={2} maxWidth='md' spacing={2}>
					{
						contacts.map((contact) => <Circle key={contact.text} item={contact} breakpoints={{xs: 12, sm: 12, md: 4, lg: 4}}/>)
					}
				</Grid>
			</Box>
		</Box>
	)
}

export default ContactView
