import  { FC, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import { motion } from 'framer-motion'

import theme from 'theme'
import { useTranslation } from 'react-i18next'

interface CardItem {
	icon: string
	name: string
	text?: string
	link?: string | {
		'ios': string
		'android': string
	}
}

interface CardI {
	item: CardItem
	index: number
	breakpoints: {
		xs?: number
		sm?: number
		md?: number
		lg?: number
	}
}

const Card: FC<CardI> = ({ breakpoints, index, item }) => {

	const { t } = useTranslation()

	const [open, setOpen] = useState<boolean>(false)

	const { icon, name, text, link } = item

	const handleClick = () => {
		if(text) {
			setOpen(!open)
		}
		else if(link && typeof link === 'string') {
			window.open(link, '_blank')
		}
	}

	return (
		<Grid item key={item.name} {...breakpoints} onClick={handleClick}>
			<motion.div
				viewport={{ once: true }}
				initial={{
					y: 50,
					opacity: 0
				}}
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 1.02 }}
				whileInView={{
					opacity: 1,
					y: 0,
					transition: {
						delay: 0.2 + index * 0.2,
						duration: 0.5
					}
				}}
				style={{height: '100%'}}
			>
				<Paper
					elevation={0}
					sx={{
						height: '100%',
						userSelect: 'none',
						padding: '3rem',
						backgroundColor: theme.palette.primary.light
					}}>
					<Box display='flex' height='100%' flexDirection='column'>
						<Box>
							<img src={icon} alt={name} width='100%' height='100px' style={{objectFit: 'contain', userSelect: 'none', pointerEvents: 'none', marginBottom: '2rem'}} />
							<Typography
								variant='h3'
								sx={{ color: theme => theme.palette.primary.contrastText, marginBottom: '1.5rem'}}>
								{name}
							</Typography>
							<Typography
								variant='body2'
								sx={{
									color: theme => theme.palette.primary.contrastText,
									marginBottom: '1.5rem'
								}}>
								{text}
							</Typography>
						</Box>
						<Box justifyContent='flex-end' flexDirection='column' display='flex' height='100%'>
							{
								link && typeof link === 'string' &&
									<Button
										variant='outlined'
										onClick={() => window.open(link, '_blank')}
										sx={{
											borderColor: theme.palette.primary.dark,
											'&:hover': {
												backgroundColor: theme.palette.secondary.dark
											}
										}}
									>
										<Typography variant='body2' sx={{ color: 'white' }}>
											{t('Try it')}
										</Typography>
									</Button>
							}
							{
								link && typeof link === 'object' &&
								<Box display='flex' flexDirection='row' alignItems='center' gap={1}>
									{
										link.android &&
										<Button
											variant='outlined'
											onClick={() => window.open(link.android, '_blank')}
											sx={{
												borderColor: theme.palette.primary.dark,
												'&:hover': {
													backgroundColor: theme.palette.secondary.dark
												}
											}}
										>
											<Typography variant='body2' sx={{ color: 'white' }}>
												Android
											</Typography>
										</Button>
									}
									{
										link.ios &&
										<Button
											variant='outlined'
											onClick={() => window.open(link.ios, '_blank')}
											sx={{
												borderColor: theme.palette.primary.dark,
												'&:hover': {
													backgroundColor: theme.palette.secondary.dark
												}
											}}
										>
											<Typography variant='body2' sx={{ color: 'white' }}>
												IOS
											</Typography>
										</Button>
									}
								</Box>
							}
						</Box>
					</Box>
				</Paper>
			</motion.div>
		</Grid>
	)
}

export default Card
