import { FC } from 'react'

import theme from 'theme'

interface GreenTextI {
	children?: any
}

const GreenText: FC<GreenTextI> = ({children}) => {
	return <span style={{color: theme.palette.secondary.main, fontWeight: 'bold'}}>{children}</span>
}

export default GreenText
