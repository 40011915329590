import { FC, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import MenuIcon from '@mui/icons-material/Menu'

import NavbarLink from './NavbarLink'
import Drawer from './Drawer'
import ChangeLanguageMenu from './ChangeLanguageMenu'

import { useGlobalContext } from 'context/GlobalContext'
import { useRefContext } from 'context/RefContext'

import logo from 'static/icons/assets/logo-black-white.svg'


export interface Link {
    text: string
	icon: JSX.Element
	onClick: () => void
}


const Navbar : FC = () => {

	const { t } = useTranslation()

	const { isMobile } = useGlobalContext()

	const [ open, setOpen ] = useState(false)

	const { ServiceRef, ProjectsRef, ContactRef } = useRefContext()

	const Links: Link[] = useMemo(() => {
		return [
			{
				text: t('Home'),
				icon: <HomeOutlinedIcon/>,
				onClick: () => window.scrollTo({top: 0, behavior: 'smooth'})
			},
			{
				text: t('Projects'),
				icon: <SettingsEthernetIcon/>,
				onClick: () => (ProjectsRef?.current as any).scrollIntoView({behavior: 'smooth'})
			},
			{
				text: t('My Passion'),
				icon: <DescriptionOutlinedIcon/>,
				onClick: () => (ServiceRef?.current as any).scrollIntoView({behavior: 'smooth'})
			},
			{
				text: t('Contact'),
				icon: <AlternateEmailIcon/>,
				onClick: () => (ContactRef?.current as any).scrollIntoView({behavior: 'smooth'})
			}
		]
	}, [t])

	if ( isMobile ){
		return (
			<>
				<Drawer open={open} setOpen={setOpen}/>
				<Box display='flex' flexDirection='row' justifyContent='end' alignItems='center' padding={5}>
					<ChangeLanguageMenu color='black' hideArrow/>
					<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' onClick={() => setOpen(true)}>
						<MenuIcon sx={{color: theme => theme.palette.primary.main}}/>
					</Box>
				</Box>
			</>
		)
	}

	return (
		<Box
			display='flex'
			flexDirection='row'
			justifyContent='space-between'
			alignItems='center'
			height={60}
			position='sticky'
			top={0}
			paddingX={3}
			sx={{
				backgroundColor: theme => theme.palette.secondary.main,
				zIndex: 1000
			}}
		>
			<img src={logo} alt='logo' width={40} height={40} style={{cursor: 'pointer'}} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}/>
			<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
				{
					Links.map( ( link, index ) => {
						return (
							<NavbarLink key={index} onClick={link.onClick} text={link.text}/>
						)}
					)
				}
				<ChangeLanguageMenu/>
			</Box>
		</Box>
	)
}

export default Navbar
