import { FC } from 'react'

import Box from '@mui/material/Box'

interface GrayLogoI {
	src: string
	alt: string
	link?: string
}


const GrayLogo : FC<GrayLogoI> = ({src, link, alt}) => {
	return (
		<Box marginX={5}>
			<a
				href={link}
			>
				<img
					src={src}
					srcSet={src}
					alt={alt}
					loading='lazy'
					style={{
						objectFit: 'contain',
						filter: 'grayscale(100% ) opacity(60%)',
						width: '100%',
						height: 'auto',
						maxWidth: '150px',
						maxHeight: '80px',
						minWidth: '100px',
						minHeight: '50px',
						userSelect: 'none',
						pointerEvents: 'none'
					}}
				/>
			</a>
		</Box>
	)
}

export default GrayLogo
