import { ReactNode, createContext, useContext, useRef } from 'react'

interface RefContextInterface {
	HomeRef: React.MutableRefObject<null>
	ServiceRef: React.MutableRefObject<null>
	ProjectsRef: React.MutableRefObject<null>
	ContactRef: React.MutableRefObject<null>
}


const RefContext = createContext<RefContextInterface>(null!)

export function RefContextProvider({ children }: { children: ReactNode }) {

	const HomeRef = useRef(null)
	const ServiceRef = useRef(null)
	const ProjectsRef = useRef(null)
	const ContactRef = useRef(null)

	const value = { HomeRef, ServiceRef, ProjectsRef, ContactRef }

	return <RefContext.Provider value={value}>{children}</RefContext.Provider>
}

export function useRefContext() {
	return useContext(RefContext)
}
