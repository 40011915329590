import { FC } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { GreenText, Tooltip } from 'components/common'

import uiux from 'static/icons/assets/ui-ux.png'

import figma from 'static/icons/skills/figma.svg'
import notion from 'static/icons/skills/notion.svg'
import photoshop from 'static/icons/skills/photoshop.png'
import premierepro from 'static/icons/skills/premierepro.png'
import aftereffects from 'static/icons/skills/aftereffects.png'
import wordpress from 'static/icons/skills/wordpress.png'

import theme from 'theme'

const tools = [
	{name: 'Figma', logo: figma},
	{name: 'Notion', logo: notion},
	{name: 'Wordpress', logo: wordpress},
	{name: 'Photoshop', logo: photoshop},
	{name: 'Premiere Pro', logo: premierepro},
	{name: 'After Effects', logo: aftereffects}
]


const  DesignServiceView: FC = () => {

	const { t } = useTranslation()


	return (
		<Box display='flex' flexDirection='row' flexWrap='wrap' justifyContent='space-around' alignItems='center'>
			<img src={uiux} alt='uxui' style={{objectFit: 'contain', maxWidth: '25rem'}}/>
			<Box marginLeft={5}>
				<Typography variant='h5' sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
					{t('UX / UI')}
				</Typography>
				<Typography variant='body2' paragraph sx={{ color: theme.palette.text.primary }} maxWidth='600px'>
					<Trans
						i18nKey='design.skills'
						components={{
							green: <GreenText/>
						}}
					/>
				</Typography>
				<Box display='flex' flexDirection='row' justifyContent='left' alignItems='center' width='100%'>
					{
						tools.map((tool) => (
							<Tooltip title={tool.name} key={tool.name} placement='bottom' isVisible>
								<img src={tool.logo} alt='tool' width='30px' style={{ marginRight: 4}}/>
							</Tooltip>
						))
					}
				</Box>
			</Box>
		</Box>
	)
}

export default DesignServiceView
