import { FC } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import theme from 'theme'

interface NavbarLinkI {
	text: string
	onClick: () => void
}

const NavbarLink: FC<NavbarLinkI> = ( { text, onClick } ) => {

	return (
		<Box
			onClick={onClick}
			marginX={2}
			paddingX={1}
			paddingY={1}
			color={theme.palette.primary.main}
			display='inline-block'
			position='relative'
			sx={{
				cursor: 'pointer',
				transition: 'transform 0.25s ease-out',
				'&:hover': {
					transform: 'scale(1.05)'
				}
			}}
		>
			<Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
				<Typography variant='h6' sx={{ color: theme.palette.primary.contrastText, ml: 1, userSelect: 'none' }}>
					{text}
				</Typography>
			</Box>
		</Box>
	)
}

export default NavbarLink
