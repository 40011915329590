import { FC } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { GreenText, Tooltip } from 'components/common'

import devops from 'static/icons/assets/devops.png'

import aws from 'static/icons/skills/aws.png'
import docker from 'static/icons/skills/docker.svg'
import git from 'static/icons/skills/git.svg'
import github from 'static/icons/skills/github.svg'
import githubactions from 'static/icons/skills/githubactions.png'
import linux from 'static/icons/skills/linux.png'
import nginx from 'static/icons/skills/nginx.svg'

import theme from 'theme'

const skills = [
	{ name: 'Git', logo: git },
	{ name: 'Github', logo: github },
	{ name: 'Github Actions', logo: githubactions },
	{ name: 'Docker', logo: docker },
	{ name: 'AWS', logo: aws },
	{ name: 'Linux', logo: linux },
	{ name: 'Nginx', logo: nginx }
]


const  DevOpsServiceView: FC = () => {

	const { t } = useTranslation()

	return (
		<Box display='flex' flexDirection='row' flexWrap='wrap' justifyContent='space-around' alignItems='center'>
			<img src={devops} alt='devops' style={{objectFit: 'contain', maxWidth: '25rem'}}/>
			<Box marginLeft={5}>
				<Typography variant='h5' sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
					{t('Operations')}
				</Typography>
				<Typography variant='body2' paragraph sx={{ color: theme.palette.text.primary }} maxWidth='600px'>
					<Trans
						i18nKey='devops.skills'
						components={{
							green: <GreenText/>
						}}
					/>
				</Typography>
				<Box display='flex' flexDirection='row' justifyContent='left' alignItems='center' width='100%'>
					{
						skills.map((skill) => (
							<Tooltip title={skill.name} key={skill.name} placement='bottom' isVisible>
								<img src={skill.logo} alt='tool' width='30px' style={{ marginRight: 4}} />
							</Tooltip>
						))
					}
				</Box>
			</Box>
		</Box>
	)
}

export default DevOpsServiceView
