import { FC } from 'react'

import Box from '@mui/material/Box'

interface SectionWrapperI {
	width: string
	height: string
	fill: string
	children: React.ReactNode
	path: path[]
	rotated?: boolean
}

interface path {
	path: string
	opacity?: number
}

const SectionWrapper: FC<SectionWrapperI> = ({ fill, width, height, path, children, rotated }) => {
	return (
		<Box position='relative'>
			<Box sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				overflow: 'hidden',
				lineHeight: 0,
				transform: rotated ? 'rotate(180deg)' : 'rotate(0deg)'
			}}
			>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1200 120'
					preserveAspectRatio='none'
					style={{
						position: 'relative',
						display: 'block',
						width: width,
						height: height
					}}>
					{
						path.map((p, index) => (
							<path
								key={index}
								d={p.path}
								opacity={p.opacity ? p.opacity : 1}
								fill={fill}
							/>
						))
					}
				</svg>
			</Box>
			{children}
		</Box>
	)
}

export default SectionWrapper
