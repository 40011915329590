import { FC } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { GreenText, Tooltip } from 'components/common'

import programming from 'static/icons/assets/programing.png'

import html5 from 'static/icons/skills/html5.svg'
import css3 from 'static/icons/skills/css3.svg'
import javascript from 'static/icons/skills/javascript.svg'
import typescript from 'static/icons/skills/typescript.svg'
import react from 'static/icons/skills/react.svg'
import node from 'static/icons/skills/nodejs.svg'
import django from 'static/icons/skills/django.svg'
import python from 'static/icons/skills/python.svg'
import mysql from 'static/icons/skills/mysql.png'

import theme from 'theme'

const skills = [
	{ logo: html5, name: 'HTML' },
	{ logo: css3, name: 'CSS' },
	{ logo: javascript, name: 'Javascript' },
	{ logo: typescript, name: 'Typescript' },
	{ logo: react, name: 'React' },
	{ logo: node, name: 'NodeJS' },
	{ logo: django, name: 'Django' },
	{ logo: python, name: 'Python' },
	{ logo: mysql, name: 'MySQL' }
]


const  DevServiceView: FC = () => {

	const { t } = useTranslation()

	return (
		<Box display='flex' flexDirection='row' flexWrap='wrap-reverse' justifyContent='space-around' alignItems='center'>
			<Box marginLeft={5}>
				<Typography variant='h5' sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
					{t('Development')}
				</Typography>
				<Typography variant='body2' paragraph sx={{ color: theme.palette.text.primary }} maxWidth='600px'>
					<Trans
						i18nKey='dev.skills'
						components={{
							green: <GreenText/>
						}}
					/>
				</Typography>
				<Box display='flex' flexDirection='row' justifyContent='left' alignItems='center' width='100%'>
					{
						skills.map((skill) => (
							<Tooltip title={skill.name} key={skill.name} placement='bottom' isVisible>
								<img src={skill.logo} alt='skill' width='30px' style={{ marginRight: 4, maxHeight: '30px' }}/>
							</Tooltip>

						))
					}
				</Box>
			</Box>
			<img src={programming} alt='programming' style={{objectFit: 'contain', maxWidth: '25rem'}}/>
		</Box>
	)
}

export default DevServiceView
