import { FC, useLayoutEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { Routes, Route } from 'react-router-dom'

import { AppRoutes } from './Routes'

import MainScreen from './app/MainScreen'
import { useTranslation } from 'react-i18next'

const App: FC = () => {

	const { i18n } = useTranslation()

	const [isLoading, setIsLoading] = useState(true)

	useLayoutEffect(() => {
		const language = localStorage.getItem('language')
		if (language) {
			i18n.changeLanguage(language).then(() => {
				setIsLoading(false)
			})
		}
		else {
			i18n.changeLanguage(navigator.language).then(() => {
				setIsLoading(false)
			})
		}
	}, [])

	return (
		<Box height='100%' sx={{ opacity: isLoading ? 0 : 1, transition: 'all 0.5s ease-in-out' }}>
			<Routes>
				<Route path='/' element={<MainScreen/>}>
					{
						AppRoutes.map(route => {
							return (
								<Route
									key={route.path}
									path={route.path}
									element={route.view}
								/>
							)
						})
					}
				</Route>
			</Routes>
		</Box>
	)
}

export default App
